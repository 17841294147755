<template>
  <section
    v-show="isLogout === true"
    class="main"
  >
    <div class="main">
      <div>
        <div class="mb-4">
          <VProgressCircular
            indeterminate
            color="primary"
            :width="3"
            :size="45"
          />
        </div>
        <div>
          <p class="textTitle">
            Logging Out...
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import fire from '../configuration/fire';

export default {
  data: () => ({
    isLogout: false,
  }),
  beforeMount() {
    if (this.$store.state.currentUser === null) {
      setTimeout(() => {
        this.$router.push('/');
      }, 100);
    } else {
      this.isLogout = true;
      setTimeout(() => {
        this.signOut();
        window.location.reload(true);
      }, 1000);
    }
  },
  methods: {
    async signOut() {
      await fire.signOut();
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../assets/stylus/text';

.main {
  min-height: calc(100vh - 48px);
  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;
}
</style>
